import React from 'react';
import '../App.css';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Details from '../components/details';
import { Grid } from '@mui/material';
import Navbar from '../components/Navbar/Navbar';

export default function BosePage() {

  const theme = createTheme();

  return ( <>
    <div className="App">
    <Navbar/>
      <header className="App-header">
          <h1>
            Welcome to <a href="#">Zameen ke Star!</a>
          </h1>
          <ThemeProvider theme={theme}>
          <CssBaseline />
          <main>
            <Container sx={{ py: 8 }} maxWidth="lg">

              <Grid container spacing={2}>
                <Details />
              </Grid>
            </Container>
          </main>
          
        </ThemeProvider>
      </header>
    </div>
  </>)

}