
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
const cards = [1, 2, 3, 4, 5, 6, 7, 8];

const ImageGrid = () => {

  const navigate = useNavigate();

  function handleClick(url) {
    navigate(url);
  }

  return (
    <>
      
                  <Grid item xs={12} sm={6} md={4}>
                    <Card
                      sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                    >
                      <CardMedia
                        component="img"
                        sx={{
                          // 16:9
                          pt: '0%',
                        }}
                        image="/images/bose-3.jpeg"
                        alt="random"
                      />
                      <CardContent sx={{ flexGrow: 1 }}>
                        <Typography gutterBottom variant="h5" component="h2">
                          Subhas Chandra Bose
                        </Typography>
                      </CardContent>
                      <div style={{textAlign: "center", marginBottom:"15px"}}>
                        <Button variant="outlined" onClick={() => { handleClick("/bose")}}>VIEW</Button>
                      </div>

                    </Card>
                  </Grid>

                {cards.map((card) => (
                  <Grid item key={card} xs={12} sm={6} md={4}>
                    <Card
                      sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                    >
                      <CardMedia
                        component="img"
                        sx={{
                          // 16:9
                          pt: '0%',
                        }}
                        image="/images/question.jpeg"
                        alt="random"
                      />
                      <CardContent sx={{ flexGrow: 1 }}>
                        <Typography gutterBottom variant="h5" component="h2">
                          More to come...
                        </Typography>
                      </CardContent>
                      <div style={{textAlign: "center", marginBottom:"15px"}}>
                        <Button variant="outlined">VIEW</Button>
                      </div>

                    </Card>
                  </Grid>
                ))}

    </>
  )
}

export default ImageGrid;