import { Button, CardMedia, Grid } from "@mui/material"
import { Box } from "@mui/system"

const Details = () => {

  return (
    <>  
      <Grid md={6}>
        <Box
          component="img"
          sx={{
            height: 800,
            width: 550
                    }}
          alt="The house from the offer."
          src="/images/bose.jpeg"
        />
      </Grid>
      <Grid md={6}>
        <div> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text 
          ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not 
          only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s
           with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus 
           PageMaker including versions of Lorem Ipsum.</div>
      </Grid>

      <Grid xs={4}>
      </Grid>
      <Grid xs={4}>
          <Button variant="contained">Click to Send Respect</Button>
      </Grid>
      <Grid xs={4}>
      </Grid>
    </>
  )
}

export default Details