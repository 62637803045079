import React from "react";
import './navbar.css';

function Navbar() {
  return (
    <>
      <nav className="navbar navbar-dark navbar-expand-sm bg-dark fixed-top">
        <div className="container">
          <a href="/" className="navbar-brand">
            <div className="brand-logo-name">
              <img className="nfi" alt="logo" src="./images/NFI-logo2.png" />
            </div>
          </a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div id="navbarCollapse" className="collapse navbar-collapse">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <a href="/aboutus" className=" nav-tab">
                  AboutUs
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
export default Navbar;