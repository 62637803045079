import React from 'react';
import '../App.css';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ImageGrid from '../components/imagegrid';
import { Grid } from '@mui/material';
import Navbar from '../components/Navbar/Navbar';

export default function HomePage() {

  const theme = createTheme();

  return ( <>
    <div className="App">
    <Navbar/>
      <header className="App-header">
          <h1>
            Welcome to <a href="#">Zameen ke Star!</a>
          </h1>
          <ThemeProvider theme={theme}>
          <CssBaseline />
          <main>
            <Container sx={{ py: 8 }} maxWidth="lg">
              <Grid container spacing={4}>
                <ImageGrid></ImageGrid>
              </Grid>
            </Container>
          </main>
          
        </ThemeProvider>
      </header>
    </div>
  </>)

}