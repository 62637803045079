import React from 'react';
import './App.css';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ImageCarousel from './components/carousel';
import ImageGrid from './components/imagegrid';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Outlet
} from "react-router-dom";

import HomePage from './pages/HomePage';
import BosePage from './pages/Bose';
import AboutUs from './pages/AboutUs/AboutUs';
import WelcomePage from './pages/WelcomePage/WelcomePage';

const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];

const theme = createTheme();

function App() {
  return (

    <>
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<WelcomePage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/bose" element={<BosePage />} />
        <Route path="/aboutus" element={<AboutUs/>} />
      </Routes>
    </BrowserRouter>
    </>

  );
}

export default App;
