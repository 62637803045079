import React from 'react';
import '../../App.css';
import './welcomepage.css'
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ImageGrid from '../../components/imagegrid';
import { Grid } from '@mui/material';
import Navbar from '../../components/Navbar/Navbar';

export default function WelcomePage() {

  const theme = createTheme();

  return (
  <>
    <Navbar />
    <header className="App-header">
        <h1 className='home-title'>
          Welcome to the Power of Natural Energy!!!
        </h1>
        <h3 className='home-click'><a className='click' href="/aboutus">Click Here</a></h3>
    </header>
    {/* </div> */}
  </>
  )
}