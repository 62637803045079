import React from 'react';
import '../../App.css';
import './AboutUs.css'
import Navbar from '../../components/Navbar/Navbar';


export default function AboutUs() {
  return (
    <>
    <div className='Nav-Container-Aboutus'>
    <Navbar/>
    </div>
    
       <div className=''>
       
      <div className='jss2'>
        <div className='jss3'>SECRET OF YOUR ENERGY</div>
      </div>
      <div className="orgdiv"></div>
      <div className='jss4'>
        <h2 className='jss5'>FIND YOUR TRUE POTENTIAL</h2>
        <div>
          <h4 className='jss6'>ACHIEVE YOUR TRUE DESTINY</h4>
          <div>
            ITS PURE SCIENCE AND A LIFETIME OF WORK...<br />
            BABA HARII SINGH AND HIS TEAM OF EXPERTS WILL TRANSFORM YOU
            THROUGH THE POWER OF NATURAL ENERGY.
          </div>
        </div>
        <div>
          <h5 className='jss7'>Astrology & Palmistry</h5>
          <div className='jss8'>If anyone Desires to achieve a goal in
            entertainment industry to become
            Successful Hero or Heroine or performering artiste<br />
            Successful Producer,<br />
            Director,<br />
            Writer,<br />
            Desire to become successful Cricket player/Brands/Corporate/Enterprises or Individuals.</div>
        </div>
        <div>
          NOW MORE FAME MORE MONEY MORE SUCCESS IS JUST A CLICK AWAY..<br />
          FOR DETAILS GO ON TO<br />
        </div>
        <div>
          <div className='jss9'>
          www.nationalframeindia.com...<br />SKY IS NOT THE LIMIT ANY MORE...<br /><br/>COME ACHIEVE YOUR DESTINY...&#9995; &#10024;
          <br/><br/>
          <img className='jss11' alt="img" src="/images/img2.jpeg" /></div>
          <div className=''>
            <img className='jss10' alt="img" src="/images/img3.jpeg" />
          </div>
        </div>
      </div>
    </div>
    </>
  )
}
